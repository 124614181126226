import { Component, OnInit, OnDestroy } from "@angular/core";
import { environment } from "../../../environments/environment";
import { WebappService } from "../../service/webapp.service";
import { AuthenticationService } from "../../service/authentication.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AngularFireAuth } from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: "app-frontsidebar",
  templateUrl: "./frontsidebar.component.html",
  styleUrls: ["./frontsidebar.component.scss"]
})
export class FrontsidebarComponent implements OnInit, OnDestroy {
  profilePhoto: any;
  profilelog: any;
  profileid: any;
  profileinfo: any;
  profileName: any;
  sitesettings: any;
  logoutbtn: boolean;
  walletMoney: any;
  modalReference: any;
  currencySymbol: any = "$";
  profileTxt: any;
  private afAuth: AngularFireAuth;
  constructor(
    private apiservice: WebappService,
    private settingsservice: AuthenticationService,
    private translate: TranslateService,
    public toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.profilePhoto = environment.apiUrl + "/gallery/users/user.png";
    this.profileName = this.translate.instant("Welcome User");
    this.logoutbtn = false;
    this.walletMoney = 0.0;
    this.profileTxt = this.translate.instant("Sign in to your Account.");
    this.settingsservice.getUserinfo().subscribe(res => {
      this.sitesettings = res;
      this.currencySymbol = this.sitesettings.currencySymbol;
    });
  }

  ngOnInit() {
    this.profilelog = JSON.parse(localStorage.getItem("user"));
    this.profileid = this.profilelog.id;
    if (this.profileid) {
      this.apiservice.profiledetails(this.profileid).subscribe(data => {
        if (data["status"] === "true") {
          this.profileinfo = data;
          this.profileTxt = this.translate.instant("View & Edit Profile");
          this.logoutbtn = true;
          if (this.profileinfo.user_image) {
            this.walletMoney = this.profileinfo.walletmoney;
            this.profilePhoto = this.profileinfo.user_image;
            this.profileName = this.profileinfo.full_name;
          }
        } else {
          /* this.toastr.error(data["message"]); */
          window.scrollTo(0, 0);
        }
      });
    }
  }

  onProfileLogout() {
    this.profilelog = JSON.parse(localStorage.getItem("user"));
    this.profileid = this.profilelog.id;
    if (this.profileid) {
      this.apiservice
        .webSigninout({ user_id: this.profileid })
        .subscribe(data => { });
    }
    this.modalReference.close();
    var previouslang = localStorage.getItem('language');
    localStorage.clear();
    if (previouslang == "" || previouslang == null)
      localStorage.setItem('language', 'en');
    else
      localStorage.setItem('language', previouslang);


    this.router.navigate(["/signin"]);
  }

  onAccountDelete() {
    this.profilelog = JSON.parse(localStorage.getItem("user"));
    this.profileid = this.profilelog.id;
    if (this.profileid) {
      this.apiservice
        .accountDelete({ user_id: this.profileid })
        .subscribe(data => {
          if (data["status"] === "true") {
            this.modalReference.close();
            var previouslang = localStorage.getItem('language');
            localStorage.clear();
            if (previouslang == "" || previouslang == null)
              localStorage.setItem('language', 'en');
            else
              localStorage.setItem('language', previouslang);
            this.toastr.success('Your Account has been deleted successfully');
            this.router.navigate(["/signin"]);
          } else if (data["status"] === "error") {
            this.toastr.success(data['message']);
          } else {
            this.toastr.success("Something went wrong");
          }
        });
    }
  }

  closeNav1() {
    const $sideNav = $(".sidenav");
    const $overlayDiv = $(".overlay_div");
    const $menuImg = $(".menu_img");
    $sideNav.css("width", "-300px");
    $overlayDiv.css("display", "none");
    $menuImg.css("display", "inline-block");
    document.getElementsByTagName("body")[0].classList.remove("overflow_div");
    document.getElementsByTagName("body")[0].classList.add("pos_rel");
  }

  open(content) {
    this.modalReference = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true
    });
    this.modalReference.result.then(
      result => {
        /* this.closeResult = `Closed with: ${result}`; */
      },
      reason => {
        /* this.closeResult = `Dismissed ${this.getDismissReason(reason)}`; */
      }
    );
  }

  delete(content) {
    this.modalReference = this.modalService.open(content, {
      ariaLabelledBy: "modal-account-delete",
      centered: true
    });
    this.modalReference.result.then(
      result => {
        /* this.closeResult = `Closed with: ${result}`; 
      },
      reason => {
        /* this.closeResult = `Dismissed ${this.getDismissReason(reason)}`; */
      }
    );
  }

  ngOnDestroy(): void {
    if (this.modalReference) {
      this.modalReference.close();
    }
  }
}
